.main {
  max-width: 1280px;
  width: 100%;
}

.mainSection {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.notFoundText {
  font-family: var(--font-primary);
  font-size: 32px;
  line-height: 135%;
  text-align: center;

  margin-top: 83px;
}
.instruction {
  font-weight: 400;

  font-size: 17px;
  line-height: 135%;
  text-align: center;
  font-family: var(--font-primary);
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-top: 20px;
}
.buttonContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 17px;
  font-weight: 500;
  font-family: var(--font-primary);
  font-style: normal;
}

.btn {
  padding: 16.5px 40px;
  cursor: pointer;
  background-color: var(--btn-color);
  color: var(--text-color-invert);
  line-height: 135%;
  border-radius: 60px;
}

.groupOFCouses {
  display: flex;
  gap: 40px;

  margin-top: 200px;
  margin-bottom: 200px;
}
.courseName {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-transform: uppercase;
}
.description {
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 17px;
  margin-top: 28px;
  line-height: 135%;
}

.atributContainer {
  margin-top: 20px;
}

.wrapperAtribut {
  margin-bottom: 30px;
  display: flex;
  gap: 40px;
}
.iconContainer {
  align-self: center;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  gap: 8px;

  p {
    align-items: center;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  }
}

.timeIcon {
  margin-top: 6px;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  background-image: url('../../images/time.svg');
  background-repeat: no-repeat;
  padding: 4px;
}

.rubleIcon {
  align-self: center;
  width: 32px;
  height: 32px;
  background-image: url('../../images/choise_icon-tenge.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.courseBox {
  padding: 40px 40px 48px 40px;
  background-color: #dad5c9;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #dad5c9;
  box-shadow: 8px 8px 30px 6px rgba(214, 212, 209, 0.6);
}

.courseImage {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-bottom: 0px;
  display: block;
  box-shadow: 8px 8px 30px 6px rgba(214, 212, 209, 0.6);
}
.contanerCourseOne,
.contanerCourseTwo {
  margin-bottom: 100px;
}
.btnWhite {
  margin-top: 40px;
  box-sizing: border-box;
  border: 1px solid #247251 !important;
  background-color: transparent;
  padding: 10px 40px 10px 40px;
  cursor: pointer;
  font-family: var(--font-primary);
  color: #247251;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  border-radius: 56px;
}
