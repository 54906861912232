@use "../../utils/variables/global.scss";

.section {
  margin-top: 140px;
  margin-left: 660px;
  max-width: 1920px;
  width: 100%;
  display: flex;
  gap: 110px;
  overflow: hidden;
}

.titleContent {
  max-width: 440px;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

.description {
  margin-top: 20px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--text-color);
}


.slide {
  max-width: 730px;
  width: 100%;
}

.slider {
  max-width: 1920px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .active {
    opacity: 1;
    transform: translateX(0);
  }

  .next {
    transform: translateX(100%);
  }

  .last {
    opacity: 0;
    transform: translateX(-100%);
  }

  &__top {
    width: 100%;
    height: 480px;
    position: relative;
  }

  &__item {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    opacity: 0;
    transition: .5s;
  }

  &__dots {
    width: 80%;
    margin-top: 80px;
    display: flex;
    gap: 8px;
  }

  &__dot {
    width: 120px;
    height: 10px;
    border: 1px solid #247251;
    border-radius: 10px;
    cursor: pointer;

    &_active {
      background-color: #247251;
    }
  }

  &__counter {
    width: 18%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Lora', serif;
    font-style: italic;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #247251;
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 80px;
    margin-left: 0;
    width: calc(100% - 40px);
    flex-direction: column;
    gap: 28px;
    overflow: hidden;
  }
  
  .titleContent {
   width: 100%;
   max-width: none;
  }
  
  .title {
    font-size: 26px;
  
    span {
      font-size: 28px;
    }
  }
  
  .description {
    display: none;
  }
  
  .slide {
    max-width: 730px;
    width: 100%;
  }
   
  .slider {
  
    &__top {
      width: 100%;
      height: 582px;
    }
  
    &__item {
      width: 100%;
    }
  
    &__dots {
      width: auto;
      margin-top: 36px;
      align-self: flex-end;
    }
  
    &__dot {
      width: 46px;
      height: 10px;
    }
  
    &__counter {
      width: 67px;
      margin-top: 16px;
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      font-size: 24px;
      line-height: 31px;
    }
  }
}
