@use "../../utils/variables/global.scss";

.section {
    padding-top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.block {
    padding: 0;
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 52px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px
}

.title {
    font-family: var(--font-primary);
    color: var(--text-color);
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
}

.profileName {
    font-family: var(--font-primary);
    color: var(--text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}

.cardsWrapper {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    list-style-type: none;
}

.item {

}

@media screen and (max-width: global.$breakpoint-small-laptop) {
    .section {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: global.$breakpoint-mobile) {
    .section {
        padding-top: 18px;
    }
    .title {
        font-size: 26px;
    }
    .titleWrapper {
        gap: 20px;
    }
    .profileName {
        font-weight: 400;
        font-size: 16px;
    }
    .block {
        gap: 38px;
    }
    .cardsWrapper {
        gap: 16px;
    }
}