@use "../../utils/variables/global.scss";

.section {
  max-width: 1280px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.block {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  width: 100%;
  max-width: 757px;
}

.span {
  display: inline-block;
  padding: 0;
  font-family: var(--font-secondary);
  font-style: italic;
  font-weight: 600;
  font-size: 58px;
  line-height: 125%;
  color: var(--text-color-secondary);
}

@media screen and (max-width: global.$breakpoint-xsmall-laptop) {
  .section {
    max-width: 696px;
  }

  .block {
    padding-top: 35px;
    padding-bottom: 0;
  }

  .title {
    font-size: 32px;
    max-width: 433px;
  }

  .span {
    padding-top: 7px;
    font-size: 32px;
    line-height: 110%;
  }

}

@media screen and (max-width: global.$breakpoint-pre-mobile) {
  .section {
    padding: 0;
    width: calc(100vw - 40px);
    justify-content: start;
  }

  .title {
    padding-bottom: 20px;
    font-size: 26px;
    max-width: 400px;
  }

  .span {
    padding-top: 3px;
    font-size: 28px;
    line-height: 110%;
    text-align: left;
    width: 250px;
  }

  .block {
    padding-top: 18px;
    padding-bottom: 20px;
  }

}