@use "../../utils/variables/global.scss";

.slider {
  max-width: 1920px;
  width: 100%;
  margin: 100px 0 0 370px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .active {
    opacity: 1;
    transform: translateX(0);
  }

  .next {
    opacity: 0;
    transform: translateX(100%);
  }

  &__top {
    max-width: 1280px;
    width: 100%;
    height: 480px;
    position: relative;
  }

  &__item {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    opacity: 0;
    transition: .5s;
  }

  &__dots {
    width: 30%;
    margin: 80px 0 0 90px;
    align-self: flex-start;
    display: flex;
    gap: 8px;
  }

  &__dot {
    width: 120px;
    height: 10px;
    border: 1px solid #247251;
    border-radius: 10px;
    cursor: pointer;

    &_active {
      background-color: #247251;
    }
  }

  &__counter {
    width: 90%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    font-family: 'Lora', serif;
    font-style: italic;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #247251;
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .slider {
    width: calc(100vw - 40px);
    margin: 80px auto 0;

    &__top {
      height: 370px;
      position: relative;
    }

    &__item {
      justify-content: flex-start;
    }

    &__dots {
      width: unset;
      margin: 44px 0 0 0;
      align-self: flex-end;
    }

    &__dot {
      width: 80px;
    }

    &__counter {
      width: 67px;
      margin: 16px 0 0 auto;
      justify-content: flex-start;
      font-size: 24px;
      line-height: 31px;
    }
  }
}
