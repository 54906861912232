@use "../../utils/variables/global.scss";

.checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 0px 1.5px var(--color-green-accent) inset;
  border-radius: 4px;
}

.textMain {
  margin-bottom: 0;
  font-family: var(--font-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: var(--text-color);
}

.content__checkbox {
  margin-bottom: 19px;
  display: flex;
  gap: 13px;
  cursor: pointer;

  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  input[type="checkbox"]:checked+span {
    background-image: url(../../images/Ellipse_1211.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .checkbox {
    min-width: 20px;
  }

  .textMain {
    font-size: 14px;
    line-height: 135%;
  }

  .content__checkbox {
    gap: 12px;
    margin-bottom: 16px;
  }
}