@use '../../utils/variables/global.scss';

.container {
  width: 100%;
  margin: 0 63px 0 80px;
}

.list {
  padding: 48px 0 41px;
  display: flex;
  justify-content: space-between;
}

.link {
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 23px;
  font-size: 17px;
  color: var(--text-color);
  transition: color 0.3s linear;

  &:hover {
    color: var(--text-color-secondary);
  }
}

.linkActive {
  color: var(--color-green-active);
}

@media (max-width: global.$breakpoint-small-laptop) {
  .container {
    margin: 0;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px 36px;
  }

  .link {
    font-weight: 500;
    line-height: 22px;
    font-size: 16px;
  }
}