@font-face {
  font-family: 'Lora';
  src: url('Lora-MediumItalic.woff2') format('woff2'),
      url('Lora-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('Lora-SemiBoldItalic.woff2') format('woff2'),
      url('Lora-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('Raleway-Medium.woff2') format('woff2'),
      url('Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('Raleway-Bold.woff2') format('woff2'),
      url('Raleway-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('Lora-Regular.woff2') format('woff2'),
      url('Lora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('Raleway-Regular.woff2') format('woff2'),
      url('Raleway-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
