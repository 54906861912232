@use "../../utils/variables/global.scss";

.section {
  padding: 226px 0;
  margin-top: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-black);
}

.container {
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-columns: 549px 640px;
  grid-template-rows: 190px 69px 66px;
  align-items: center;
  gap: 24px 111px;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color-invert);

  span {
    font-family: var(--font-secondary);
    color: var(--text-color-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
  }
}

.description {
  grid-row: 2;
  grid-column: 1;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: var(--text-color-invert);
}

.btnWrapper {
  grid-row: 3;
  grid-column: 1;
  margin-top: 16px;
  max-width: 298px;
  position: relative;
  z-index: global.$layer-of-decoration;
}

.imgWrapper {
  grid-row: span 3;
  grid-column: 2;
  max-width: 640px;
  width: 100%;
  position: relative;
  z-index: global.$layer-of-decoration;
}

.image {
  max-width: 620px;
  width: 100%;
  height: auto;
  z-index: global.$layer-of-decoration;
}

.imageBorder {
  border: 1px solid #121210;
  border-radius: 200px 100px 300px 20px;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 620px;
  height: 494px;
  z-index: global.$layer-under-content;
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    padding: 40px 0;
    margin-top: 80px;
  }
  
  .container {
    width: calc(100% - 40px);
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 24px;
  }
  
  .title {
    font-size: 26px;
  
    span {
      font-size: 28px;
    }
  }
  
  .description {
    grid-row: 3;
    grid-column: 1;
    margin-top: 4px;
    font-size: 16px;
    line-height: 135%;
  }
  
  .btnWrapper {
    grid-row: 4;
    width: 325px;
    margin: 0;
  }
  
  .imgWrapper {
    grid-row: 2;
    grid-column: 1;
  }

  .imageBorder {
    display: none;
  }
}
