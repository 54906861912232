@use "../../utils/variables/global.scss";

.sidebar {
  display: block;
  box-sizing: border-box;
  width: 400px;
  height: 580px;
  background-color: var(--color-white);
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    width: 298px;
    height: 399px;
  }

  @media screen and (max-width: global.$breakpoint-tablet) {
    top: 181px;
    left: 0;
    width: 320px;
  }
}

.share {
  position: absolute;
  top: 24px;
  right: 24px;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    top: 16px;
    right: 14px;
  }

  @media screen and (max-width: global.$breakpoint-tablet) {
    right: 40px;
  }
}

.innerBlock {
  max-width: 400px;
  height: 580px;
  justify-content: center;
  text-align: center;
  padding: 178px 42.5px 43px 42.5px;
  font-family: var(--font-primary);

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    padding: 68px 18.5px 40px;
  }

  @media screen and (max-width: global.$breakpoint-tablet) {
    height: fit-content;
  }
}

.title, .coursePrice, .duration, .buttonContainer, .giftContainer, .giftIcon, .gift {
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
  }
}

.coursePrice {
  margin-top: 8px;
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  line-height: 135%;
  font-weight: 700;
  letter-spacing: 2px;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    font-size: 20px;
    margin-top: 16px;
    letter-spacing: 1px;
  }
}

.duration {
  margin-top: 16px;
  font-size: 17px;
  white-space: nowrap;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    font-size: 16px;
    line-height: 135%;
  }
}

.buttonContainer {
  margin-top: 40px;
  width: 315px;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    margin-top: 70px;
    width: 261px;
  }
}

.giftContainer {
  margin-top: 106px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-content: center;
  align-items: center;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    margin-top: 19px;
  }
}

.giftIcon {
  width: 32px;
  height: 32px;
}

.gift {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    font-size: 16px;
  }
}