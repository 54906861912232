@use "../../utils/variables/global.scss";

.section {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.block {
    padding: 0;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 110px;
}

.formContainer {
    width: 620px;
    height: 760px;
    border-radius: 20px;
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: global.$base-layer;
    justify-content: center;
    box-sizing: border-box;
}

.responseStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 48px;
}

.activeResponseStep {
    padding-bottom: 0px;
}

.title {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 135%;
    color: var(--text-color);
    padding: 0 0 10px 0;
}

.activeTitle {
    padding: 0 0 20px 0;
}

.text {
    width: 510px;
    height: 54px;
    font-family: var(--font-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    color: var(--text-color);
    text-align: center;
}

.inputsWrapper {
    width: 510px;
}

.buttonWrapper {
    padding-top: 40px;
    padding-bottom: 0;
}

.activeButtonWrapper {
    padding-top: 60px;
}

@media screen and (max-width: global.$breakpoint-mobile) {

    .formContainer {
        margin: 0 20px;
        width: 100%;
        height: 100%;
        padding: 40px 20px;
    }

    .inputsWrapper {
        width: 100%;
    }

    .title {
        font-size: 20px;
        padding: 38px 0 34px 0;
    }

    .buttonWrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 280px;
        display: flex;
        flex-direction: column;
    }

    .text {
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 145%;
        padding-bottom: 28px;
    }

    .responseStep {
        padding-bottom: 0;
    }

}