@use "../../utils/variables/global.scss";

.block {
  margin: 88px auto 0;
  max-width: 1280px;
  width: 100%;
  display: flex;
}

.topContainer {
  display: flex;
  justify-content: left;
  padding-bottom: 88px;
  width: 80vw;
}

.mapWrapper {
  width: 620px;
  height: 360px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10%;
}

.contactsWrapper {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  white-space: pre-wrap;
}

.contactsTitle {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);

  span {
    font-weight: 500;
  }
}

@media screen and (max-width: 876px) {
  .block {
    margin: 100px auto 0;
    padding: 0 24px;
    flex-direction: column;
  }

  .topContainer {
    padding-bottom: 48px;
    width: 94vw;
  }

  .mapWrapper {
    width: 338px;
    height: 194px;
    margin: 0;
  }

  .contactsWrapper {
    height: 194px;
    margin: 0;
    gap: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 696px) {
  .block {
    margin: 60px 0 0;
    flex-direction: column;
    width: 100%;
    max-width: 656px;
    padding: 0;
  }
  
  .topContainer {
    padding-bottom: 53px;
    flex-direction: column-reverse;
    align-items: center;
    align-self: center;
    gap: 55px;
    width: calc(100% - 40px);
  }

  .mapWrapper {
    width: 100%;
    height: 51vw;
  }
  
  .contactsWrapper {
    gap: 12px;
    padding: 0;
    height: fit-content;
    align-self: baseline;
  }
  
  .contactsTitle {
    font-size: 14px;
  
    span {
      font-weight: 400;
    }
  }
}
