@use "../../utils/variables/global.scss";

.page {
  display: flex;
  max-width: 1280px;
  width: 100%;
  gap: 40px;
  margin-bottom: 60px;
  margin-top: 88px;
  position: relative;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    width: 94%;
    margin-top: 36px;
  }

  @media screen and (max-width: global.$breakpoint-tablet) {
    width: 89%;
  }
}
