@use "../../utils/variables/global.scss";

.label {
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.input {
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 24px;
  font-family: var(--font-primary), sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.04em;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--color-black);
  background-color: transparent;

  &:valid {
    border-bottom-color: var(--color-green-valid);
  }

  &.invalid {
    border-bottom-color: var(--color-red);
  }

  &.valid {
    border-bottom-color: var(--color-green-valid);
  }
}

.error {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5%;
  color: var(--color-red);
  font-family: var(--font-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
}

.phoneBorder input{
border: none;
}

@media screen and (max-width: global.$breakpoint-mobile) {

  .input {
    margin: 0;
    padding: 6px 0;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: 0.6px;

    &:valid {
      border-bottom-color: var(--color-green-valid);
    }

    &.invalid {
      border-bottom-color: var(--color-red);
    }
  }

  .error {
    position: static;
  }

  .label {
    margin-bottom: 19px;

  }

}

// Styles for Storybook
.formContainer {
  width: 500px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: global.$base-layer;
}

.doublePassword {
  height: 200px;
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .formContainer {
    margin: 0 20px;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
  }
}