.popup {
  width: 1280px;
  background-color: var(--color-background-color);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-top: 80px;
  margin-bottom: auto;
  padding-bottom: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: 'Raleway', 'Arial', sans-serif;

  @media screen and (max-width: 1320px) {
    width: 744px;
    margin-top: 144px;
    box-shadow: none;
  }

  @media screen and (max-width: 743px) {
    margin-top: 20px;
    width: 360px;
  }
}

.closeButton {
  position: absolute;
  top: 19px;
  right: 19px;
  background-image: url('../../images/cancel_icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
