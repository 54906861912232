.container {
  border: 1px solid var(--color-green-accent);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selected {
  border: none;
  background-color: var(--color-green-primary);
}

.button {
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.time {
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
}

.price {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: var(--color-green-accent);
}