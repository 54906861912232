@use "../../utils/variables/global.scss";

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  transition: .5s;
  z-index: 200;

  &_open {
    opacity: 1;
    visibility: visible;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: global.$layer-under-content;
}

.content__wrapper {
  padding: 0 75px;
  margin: 48px 0;
  display: flex;
  flex-direction: column;
}

.button__close {
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 18px;
  right: 18px;
  background: transparent;
  cursor: pointer;
  transition: .5s;

  &:hover {
    opacity: .7;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #fff;
  }
}

.content {
  width: 660px;
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.button__container {
  margin: 0 auto;
}

.image {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}

.title {
  margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
}

.description {
  margin-bottom: 32px;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
}

.hours {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
}

.comment_heading {
  margin-bottom: 4px;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #819286;
  text-transform: uppercase;
}

.textarea {
  width: 99%;
  min-height: 98px;
  margin-bottom: 24px;
  background-color: #CCDAC6;
  border-bottom: 1px solid #121210;
  resize: none;
}

@media screen and (max-width: global.$breakpoint-tablet) {
  .content {
    width: 78vw;
    max-width: 576px;
  }

  .button__close {
    top: 26px;
    right: 26px;
  }

  .title {
    font-size: 20px;
    line-height: 135%;
    margin-bottom: 12px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 28px;
  }

  .content__wrapper {
    margin: 28px 0 44px;
    padding: 0 40px;
  }

  .comment_heading {
    text-transform: none;
  }

}

@media screen and (max-width: global.$breakpoint-mobile) {

  .popup {
    align-items: flex-start;
  }

  .content {
    width: 100%;
  }

  .image {
    height: calc(100vw*0.3);
  }

  .title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 27px;
  }
  
  .description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 21px;
  }

  .hours {
    margin-top: 50px;
    font-size: 14px;
    line-height: 135%;
  }

  .comment_heading {
    font-size: 15px;
    line-height: 20.25px;
    letter-spacing: 0.6px;
  }

  .textarea {
    margin-bottom: 20px;
  }

  .content {
    width: 100%;

    .button__close {
      top: 10px;
      right: 10px;
  
      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }
  }

  .content__wrapper {
    padding: 0 20px;
    margin: 20px 0 40px;
  }
}

