@use "../../utils/variables/global.scss";

.section {
  margin-top: 330px;
  max-width: 1280px;
  width: 100%;
}

.subtitle {
  font-family: var(--font-primary);
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-color);
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

.list {
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
}

.item {
  max-width: 400px;
  width: 100%;
}

.ellipse {
  width: 160px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/Ellipse.svg');
  background-repeat: no-repeat;
  background-position: top center;
  font-family: var(--font-secondary);
  -webkit-text-stroke: 1px var(--text-color-secondary);
  color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 140%;
  text-align: center;
}

.cardTitle {
  margin-top: 30px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #247251;
}

.description {
  margin-top: 8px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 58px;
    padding-left: 20px;
  }

  .subtitle {
    font-size: 10px;
    letter-spacing: 0.02em;
  }

  .title {
    font-size: 26px;

    span {
      font-size: 28px;
    }
  }

  .list {
    margin-top: 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 16px;
  }

  .list::-webkit-scrollbar {
    display: none;
}

  .list::-webkit-scrollbar-thumb {
    display: none; 
  }

  .item {
    flex: 0 0 253px;
  }

  .ellipse {
    margin-top: 16px;
    width: 87px;
    height: 68px;
    font-size: 44px; 
    background-size: 90%;
  }

  .cardTitle {
    margin-top: 26px;
    font-size: 18px;
    line-height: 21px;
  }

  .description {
    font-size: 16px;
  }
}
