@use "../../utils/variables/global.scss";

.section {
  padding: 0 320px 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .paragraph {
    grid-column: 1;
    margin: 0;
    font-size: 20px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    line-height: 135%;

    &:nth-of-type(1) {
      grid-row: 2;
    }
  
    &:nth-of-type(2) {
      grid-row: 3;
    }
  }

  .title {
    grid-column: 1;
    grid-row: 1;
    font-size: 56px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 0 0 24px;

    .span {
      margin: 0;
      color: #6AAF12;
      font-size: 58px;
      font-family: var(--font-secondary);
      font-style: italic;
      font-weight: 600;
      line-height: 110%;
    }
  }
}

.list {
  margin-top: 136px;
  display: flex;
  flex-direction: column;
  gap: 88px;
}


@media screen and (max-width: 1550px) {
  .section {
    padding: 0 160px 116px;

    .decoration {
      display: none;
    }
  }
  
}


@media screen and (max-width: 1350px) {
  .section {
    padding: 0 80px 116px;
  }
  
}

@media screen and (max-width: 1190px) {
  .section {
    padding: 0 40px 116px;
  }
  
}

@media screen and (max-width: 950px) {
  .section {
    padding: 0 20px 116px;
  }
  
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section{
    max-width: 320px;
    .title {
      font-size: 26px;
      box-sizing: border-box;

      .span {
        display: block;
        font-size: 26px;
      }
    }

    .paragraph {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
    }

    .description {
      font-size: 16px;
    }
  } 
}