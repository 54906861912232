@use "../../utils/variables/global.scss";

.GetToKnowUs {
  display: grid;
  grid-template-columns: 440px 336px 336px;
  grid-template-rows: min-content min-content min-content min-content;
  gap: 0 64px;
  margin-top: 100px;
  margin-bottom: 200px;
  justify-content: space-between;

  .img {
    grid-row: span 2;
    grid-column: 1;
    border-radius: 20px;
    object-fit: cover;
    width: 400px;
    height: 383px;
  }

  .heading {
    grid-row: 1;
    grid-column: 2/span 2;
    margin: 0;
    .smallTitle {
      font-family: var(--font-primary);
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
    }

    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 110%;

    .title {
      font-family: var(--font-primary);
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 110%;
    }

    .span {
      font-family: var(--font-secondary);
      font-style: italic;
      font-weight: 600;
      font-size: 58px;
      line-height: 110%;
      color: var(--text-color-secondary);
    }
  }

  .text {
    margin-top: 25px;

    &:nth-of-type(1) {
      grid-row: 2;
      grid-column: 2
    }

    &:nth-of-type(2) {
      grid-row: 2;
      grid-column: 3
    }
  }

  .description {
    margin: 0;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22.95px;
  }

  .description:not(:first-child) {
    margin-top: 10px;
  }
}


@media screen and (max-width: global.$breakpoint-mobile) {
  .GetToKnowUs {
    grid-template-columns: calc(100vw - 40px);
    grid-template-rows: min-content min-content min-content min-content;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
  
    .img {
      grid-row: 2;
      grid-column: 1;
      margin-top: 9px;
      width: 100%;
      max-height: 383px;
      height: calc((100vw - 40px)*0.85);
    }
  
    .heading {
      grid-row: 1;
      grid-column: 1;
      font-size: 26px;

      .smallTitle {
        font-size: 10px;
        letter-spacing: 0.2px;
        line-height: 13px;
      }
  
      .title {
        font-size: 26px;
      }
  
      .span {
        font-size: 28px;
      }
    }
  
    .text {
      margin: 0;
      width: 100%;
  
      &:nth-of-type(1) {
        grid-row: 3;
        grid-column: 1;
      }
  
      &:nth-of-type(2) {
        grid-row: 4;
        grid-column: 1;
      }
    }
  
    .description {
      width: 100%;
      font-size: 16px;
      line-height: 21.6px;
    }
  }
}
