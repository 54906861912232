@use "../../utils/variables/global.scss";

.section {
  width: 100%;
  display: flex;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    justify-content: center;
  }
}

.containerInfo {
  display: flex;
  gap: 40px;
  max-width: 840px;

  @media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
    gap: 20px;
    max-width: 700px;
  }

  @media screen and (max-width: global.$breakpoint-tablet) {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-width: 984px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.atributContainer {
  margin-top: 20px;
}

.iconContainer {
  align-self: center;
}