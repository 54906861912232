@use "../../utils/variables/global";

.image {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-bottom: 0px;
  display: block;
  box-shadow: 8px 8px 30px 6px rgba(214, 212, 209, 0.6);
}

.courseBox {
  padding: 40px 40px 30px 40px;
  background-color: #dad5c9;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 8px 8px 30px 6px rgba(214, 212, 209, 0.6);
}

.title {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.wrapperAtribut {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  gap: 50px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p {
    align-items: center;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  }
}

.timeIcon, .rubleIcon {
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center;
}

.timeIcon {
  background-image: url('../../images/time.svg');
}

.rubleIcon {
  align-self: center;
  background-image: url('../../images/choise_icon-tenge.svg');
}

.description {
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
}

.btn {
  background-color: transparent;
  border: 1.5px solid #247251;
  border-radius: 56px;
  padding: 10px 40px;
  width: 320px;
  height: 56px;
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;
  color: #247251;
  margin-top: 60px;
}

@media screen and (max-width: global.$breakpoint-tablet-for-course-page) {
  .container {
    width: 220px;
  }

  .image {
    height: 136px;
  }

  .courseBox {
    padding: 20px 16px 32px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 135%;
    text-transform: none;
  }

  .wrapperAtribut {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .timeIcon {
    background-image: url('../../images/time-small.svg');
  }

  .rubleIcon {
    background-image: url('../../images/choise_icon-tenge-small.svg');
  }

  .description {
    font-size: 16px;
  }

  .btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    padding: 11px 21px;
    margin-top: 52px;
  }
}

@media screen and (max-width: global.$breakpoint-tablet) {
  .container {
    width: 320px;
  }

  .image {
    height: 198px;
    width: 320px;
  }

  .courseBox {
    padding: 24px 24px 40px;
  }

  .wrapperAtribut {
    flex-direction: row;
    gap: 20px;
  }

  .timeIcon, .rubleIcon {
    background-image: url('../../images/time.svg');
  }

  .btn {
    width: 272px;
  }
}
