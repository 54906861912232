@use '../../utils/variables/global.scss';

.section {
  width: 100%;
  box-sizing: border-box;
  padding: 0 320px 116px;
  display: flex;
  justify-content: space-between;
  margin-top: 31px;

  .left {
    .mobileImage {
      display: none;
    }

    .title {
      font-family: Raleway;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      color: #121210;
      margin-bottom: 24px;

      span {
        font-family: Lora;
        font-size: 58px;
        font-style: italic;
        font-weight: 600;
        line-height: 110%;
        color: #6aaf12;
      }
    }

    .paragraph {
      margin: 0;
      color: #121210;
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      width: 584px;
    }

    .otherElements {
      margin-top: 40px;
    }
  }

  .right {
    border: 1px solid #000;
    box-sizing: border-box;
    width: 426px;
    height: 596px;
    border-radius: 600px 600px 0px 0px;
    position: relative;
    top: 16px;
    right: 16px;

    .desktopImage {
      object-fit: cover;
      border-radius: 600px 600px 0px 0px;
      position: relative;
      bottom: 16px;
      left: 16px;
      width: 426px;
      height: 596px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .section {
    padding: 0 160px 116px;
  }
}

@media screen and (max-width: 1350px) {
  .section {
    padding: 0 80px 116px;
  }
}

@media screen and (max-width: 1190px) {
  .section {
    padding: 0 40px 116px;
  }
}

@media screen and (max-width: 950px) {
  .section {
    padding: 0 20px 116px;
  }
}

@media screen and (max-width: 1080px) {
  .section {
    padding: 0 20px 20px;

    .left {
      .mobileImage {
        display: block;
        object-fit: cover;
        border-radius: 20px 20px 300px 20px;
        width: 320px;
        height: 179px;
        margin-bottom: 20px;
      }

      .title {
        font-family: Raleway;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;

        span {
          font-family: Lora;
          font-size: 28px;
          font-style: italic;
          font-weight: 600;
          line-height: 110%;
        }
      }

      .paragraph {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%;
        width: 315px;
      }
    }
  }
}
