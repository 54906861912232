:root {
  --text-color: #121210;
  --text-color-secondary: #6AAF12;
  --text-color-invert: #FFF;

  --font-primary: 'Raleway';
  --font-secondary: 'Lora';

  --btn-color: #247251;

  --bg-green-primary: #CCDAC6;
  --bg-color-white: #FFF;

  --color-green-primary: #CCDAC6;
  --color-green-accent: #247251;
  --color-green-accent-active: #299D6D;
  --color-green-secondary: #6AAF12;
  --color-green-grey: #819286;
  --color-green-grey-light: #E2E9E4;
  --color-green-valid: #1E8E0B;
  --color-green-active: #185F41;
  --color-white: #FFFFFF;
  --color-white-active: #BFC8BF;
  --color-black: #121210;
  --color-red: #BF0000;
  --color-beige-primary: #F0ECE2;
  --color-beige-secondary: #DAD5C9;
  --color-background-color: #F5F4F1;
  --color-background-of-the-error-message: rgba(191, 0, 0, 0.15);
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-light-grey: #9fb4a5;
  --color-green-gray-4: rgba(159, 180, 165, 0.4);
  --color-background-skeleton-color: #ecdfdf;
  --color-background-skeleton-image: #d9cece;
  --color-background-skeleton-main: #beb5b5;
}

button {
  border: none;
}

@mixin h2_font {
  font-size: 32px;
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: 135%;
}

$breakpoint-mobile: 550px;
$breakpoint-pre-mobile: 670px;
$breakpoint-ipad-mini: 720px;
$breakpoint-tablet: 743px;
$breakpoint-xsmall-laptop: 1100px;
$breakpoint-card-curses: 1200px;
$breakpoint-small-laptop: 1300px;
$breakpoint-tablet-for-course-page: 1340px;
// .swiper-slide {
//   transition: all 0.5s ease;
//   padding: 40px 0;
// }

// .swiper-slide-active {
//   // width: 400px;
//   transform: scale(1.2) !important;
// }

$layer-under-content: -1;
$base-layer: 0;
$layer-of-decoration: 1;
$layer-of-popup: 2;