@use "../../utils/variables/global.scss";

.section {
  margin-top: 140px;

  max-width: 811px;
  width: 100%;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  text-align: center;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

// .list {
//   margin-top: 64px;
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
// }

.list {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.acorСontainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #DAD5C9;
}
.acorСontainer .answerWrapper {
  max-width: 660px;
  width: 100%;
  margin-top: 20px;
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);
  height: 0;
  transition: color 0.5s, padding 0.5s;
  overflow: hidden;
  font-family: Verdana, sans-serif;
}

.acorСontainer label {
  cursor: pointer;
  display: block;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 135%;
  color: var(--text-color);
  z-index: 100;
  margin: 0 0 5px;
  transition: color 0.35s;
}

.acorСontainer input {
  display: none;
}
.acorСontainer label:before {
  width: 40px;
  height: 40px;
  content: '';
  background-image: url('../../images/icon_question.svg');
  background-size: cover;
  float: right;
  transition: transform 0.35s;
  transform: rotate(0deg);
}

.acorСontainer input:checked + label:before {
  transition: transform 0.35s;
  transform: rotate(45deg);
}
.acorСontainer input:checked + label + .answerWrapper {
  padding-top: 20px;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: -5px;
  color: #000;
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 80px;
    width: calc(100% - 40px);
  }
  
  .title {
    font-size: 26px;
    text-align: left;
  
    span {
      font-size: 28px;
    }
  }
  
  .list {
    margin-top: 32px;
    gap: 12px;
  }

  .acorСontainer {
    padding-bottom: 12px;
  }
  
  .acorСontainer .answerWrapper {
    margin-top: 8px;
    font-size: 14px;
  }
  
  .acorСontainer label {
    font-size: 16px;
    margin: 0;
  }
  
  .acorСontainer label:before {
    width: 24px;
    height: 24px;
  }
  
  .acorСontainer input:checked + label + .answerWrapper {
    padding-top: 8px;
    display: flex;
    width: 100%;
    height: auto;
    margin-top: -5px;
    color: #000;
  }
}
