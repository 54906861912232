@use "../../utils/variables/global.scss";

.chip {
  position: relative;
  all: unset;
  cursor: pointer;
  border: 1px solid var(--color-green-accent);
  border-radius: 12px;
  padding: 8px 15px;
  height: 52px;
  background-color: var(--color-background-color);
  font-family: var(--font-primary), sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;
  color: var(--color-green-accent);
  box-sizing: border-box;
  transition: all .3s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg path {
    transition: all .3s ease;
  }

  &:hover {
    border: 1px solid var(--color-green-grey);
    background-color: var(--color-green-grey);
    color: var(--color-background-color);

    svg path {
      fill: var(--color-background-color);
    }

  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.active {
  border: 1.5px solid var(--color-green-accent-active);
  background-color: var(--color-green-accent);
  color: var(--color-background-color);

  svg path {
    fill: var(--color-background-color);
  }
}

.fill {
  width: 100%;
}

.filterCounter {
  position: absolute;
  top: -11px;
  right: 20px;
  width: 24px;
  height: 24px;
  font-family: var(--font-primary), sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: var(--color-white);
  border-radius: 100%;
  background-color: var(--text-color);
  border: 2px solid var(--color-background-color);
  text-align: center;
  box-sizing: border-box;
  display: none;
}

.number {
  display: inline;
}


@media screen and (max-width: global.$breakpoint-pre-mobile) {
  .chip {
    border: 1px solid var(--color-green-primary);
    padding: 11px 16px;
    height: 44px;
    font-size: 16px;


    &:hover {
      border: 1.5px solid var(--color-green-accent-active);
      background-color: var(--color-green-accent);
      color: var(--color-background-color);

      svg path {
        fill: var(--color-background-color);
      }
    }
  }

  .border {
    border: 1px solid var(--color-green-accent);
  }
}