@use "../../utils/variables/global.scss";

.block {
  width: 400px;
  border-radius: 20px;
  border: 1px solid var(--color-green-grey);
}

.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  
}

.link {
  display: flex;
  flex-direction: row;
  padding: 20px 16px;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid var(--color-green-grey);
  color: var(--text-color);
  text-decoration: none;
  &:hover {
    color: var(--color-green-secondary);
  }
}

li {
    &:last-child a {
    border-bottom: unset;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.title {
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 135%;
  font-size: 20px;
  color: inherit;
  margin: 0;
  padding: 0;
  transition: all .3s ease;
  
}

.linkActive .title {
  font-weight: 500;
}

@media screen and (max-width: global.$breakpoint-xsmall-laptop) {
  .block {
      display: none;
  }
}
