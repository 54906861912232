@use "../../utils/variables/global.scss";

.section {
  max-width: 1280px;
  width: 100%;
  margin-top: 140px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

.titleBtn {
  padding: 18px 40px;
  border: 1px solid #000000 !important;
  border-radius: 60px;
  background-color: transparent;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 80px;
  }

  .titleWrapper {
    margin-left: 20px;
  }

  .title {
    font-size: 26px;

    span {
      font-size: 28px;
    }
  }
}
