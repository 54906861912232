.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.titleWrapper {
  max-width: 620px;
  width: 100%;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: var(--text-color)
}

.description {
  margin-top: 24px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22.95px;
  color: var(--text-color)
}

.certificateWrapper {
  margin-top: 88px;
  padding: 100px 220px 88px 220px;
  background-color: #CCDAC6;
  /*  width: 1280px;
    height: 603px;*/
  z-index: 1;
  max-width: 840px;
  border-radius: 20px;
}

.titleCertificate {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
}

.span {
  font-family: var(--font-secondary);
  font-style: italic;
  font-weight: 600;
  font-size: 58px;
  line-height: 110%;
  color: var(--btn-color);
}

.form {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .wrapper {
    display: flex;
    flex-direction: column;
    min-width: 440px;

  }

  .wrapper:nth-child(2n) {
    margin-left: 110px;
    max-width: 290px;
  }

  .label {
    display: flex;
    position: relative;
    flex-direction: column;

    h3 {
      max-width: 290px;
      align-items: flex-start;
      font-family: var(--font-primary);
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      margin-top: 30px;
    }
  }

  span {
    display: flex;
    align-items: center;
    bottom: -80%;
    color: #BF0000;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.04em;
  }

  .input {
    font-family: var(--font-primary);
    width: 100%;
    padding-bottom: 4px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #121210;;
    font-size: 17px;
    line-height: 22.95px;
    font-weight: 500;
    margin-top: 20px;
    box-shadow: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #CCDAC6 inset !important;
  }
  .input:nth-child(2n) {
    max-width: 290px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .checkbox {
    margin-top: 17px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked + span {
      background-image: url(../../images/Ellipse_1211.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }

    span {
      width: 20px;
      height: 20px;
      border: 1px solid #247251;
      border-radius: 4px;
    }

    p {
      margin-bottom: 0;
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 23px;
      color: #000;
      max-width: 301px;
    }
  }

  .btnWrapper {
    max-width: 293px;
    margin-top: 40px;
    position: relative;
  }
}

p {
  align-items: flex-start;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
}

.margin {
  margin-top: 5px;
}

.errorText {
  margin-top: 5px;
  min-height: 20px;
}