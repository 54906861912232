@use "../../utils/variables/global.scss";

.block {
  padding-bottom: 103px;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #819286;
}

.otherContainer {
  margin: 88px auto 0;
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-rows: 112px 88px;
  justify-content: space-between;
}

.linkList {
  max-width: 128px;
  display: flex;
  height: 32px;
  column-gap: 28px;
  grid-column: 1;
  grid-row: 2;
}

.linkVk {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../images/icon_vk.svg');
}

.linkFb {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../images/icon_facebook.svg');
}

.linkTg {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../images/icon_telegram.svg');
}

.linkInstagram {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../images/icon_instagram.svg');
}

.navList {
  display: grid;
  padding-right: 20px;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, 1fr);
  gap: 40px 56px;
  grid-column: 2;
  grid-row: 1 / 2;
}

.navlink {
  text-decoration: none;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);
  transition: all .3s linear;
}

.navlink:hover {
  color: var(--color-green-accent);
}

@media screen and (max-width: 876px) {
  .block {
    border: none;
  }

  .otherContainer {
    margin: 35px auto 0 24px;
    grid-template-rows: none;
    grid-template-areas: 
      "logo navigation"
      "linkList navigation";
    width: 88vw;
    max-width: 655px;
    column-gap: 80px;
    row-gap: 5px;
  }

  .linkList {
    grid-area: linkList;
  }

  .navigation {
    grid-area: navigation;
    align-self: center;
  }

  .navList {
    grid-template-columns: repeat(3, 112px);
    grid-template-rows: repeat(2, 23px);
    grid-template-areas: 
      "about gallery courses"
      "shop certificates blog";
    gap: 40px 32px;
    padding: 0;
  }

  .about {
    grid-area: about;
  }

  .gallery {
    grid-area: gallery;
  }

  .courses {
    grid-area: courses;
  }

  .shop {
    grid-area: shop;
  }

  .certificates {
    grid-area: certificates;
  }

  .blog {
    grid-area: blog;
  }
}

@media screen and (max-width: 696px) {
  .block {
    padding-bottom: 44px;
    margin-top: 85px;
  }

  .otherContainer {
    margin: 0;
    padding: 0 20px;
    flex-direction: column;
    width: calc(100% - 40px);
    grid-template-areas: 
      "logo linkList"
      "navigation navigation";
    column-gap: 0;
  }

  .logoContainer {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
  }

  .linkList {
    margin-top: 10px;
    max-width: 115px;
    gap: 8px;
    justify-self: center;
  }

  .navigation {
    order: 3;
    margin-top: 22px;
  }

  .about {
    grid-row: 1;
    grid-column: 1;
  }

  .shop {
    grid-row: 2;
    grid-column: 1;
  }

  .gallery {
    grid-row: 1;
    grid-column: 2;
  }

  .certificates {
    grid-row: 2;
    grid-column: 2;
  }

  .courses {
    grid-row: 1;
    grid-column: 3;
  }

  .blog {
    grid-row: 2;
    grid-column: 3;
  }

  .navlink {
    font-size: 14px;
  }

  .navList {
    grid-template-columns: repeat(3, 90px);
    gap: 27px 32px;
  }
}