@use '../../utils/variables/global.scss';

.section {
  max-width: 1280px;
  width: 100%;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

.titleBtn {
  padding: 18px 40px;
  border: 1px solid #000000 !important;
  border-radius: 60px;
  background-color: transparent;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 78px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.cardSkeletonList {
  display: flex;
  flex-direction: column;
  gap: 78px;
  align-items: center;
}

.notFoundWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.notFoundImage {
  width: 100%;
  max-width: 305px;
}

.notFoundTitle {
  text-wrap: balance;
  margin: 0;
  padding: 40px 0 20px;
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 32px;
  line-height: 135%;
  color: var(--text-color);
  text-align: center;
}

.notFoundText {
  text-wrap: balance;
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);
  text-align: center;
}

.paginationWrapper {
  padding-top: 80px;
}

.hideCards {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: global.$breakpoint-small-laptop) {
  .list {
    gap: 35px;
  }
}

@media screen and (max-width: global.$breakpoint-xsmall-laptop) {
  .notFoundImage {
    max-width: 218px;
  }

  .notFoundTitle {
    font-size: 20px;
    padding: 20px 0 12px;
  }

  .notFoundText {
    font-size: 16px;
  }
}

@media screen and (max-width: global.$breakpoint-card-curses) {
  .cardSkeletonList {
    gap: 35px;
  }
}

@media screen and (max-width: global.$breakpoint-pre-mobile) {
  .list {
    margin-top: 28px;
    gap: 20px;
    align-items: center;
  }

  .cardSkeletonList {
    padding-top: 30px;
    gap: 20px;
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .notFoundImage {
    max-width: 159px;
  }

  .notFoundTitle {
    font-size: 26px;
    line-height: 110%;
    padding: 12px 0 16px;
  }

  .notFoundText {
    font-size: 17px;
  }
}
