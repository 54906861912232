@use "../../utils/variables/global.scss";

.AtmosphereBox {
  max-width: 1280px;
  .title {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 110%;
  }
  .span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
  
  .sliderContainer {
    display: none;
  }

  .container {
    margin-top: 40px;
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-rows:repeat(4, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    .child1 {
      grid-area: 1 / 1 / 3 / 2;
      width: 510px;
      height: 370px;
      border-radius: 400px 20px 20px 20px;
    }

    .child2 {
      grid-area: 1 / 2 / 3 / 3;
    }

    .child3 {
      grid-area: 3 / 2 / 5 / 3;
    }

    .child4 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .child5 {
      grid-area: 4 / 1 / 5 / 2;
    }
  }

}

@media screen and (max-width: global.$breakpoint-mobile) {
  .AtmosphereBox {
    width: calc(100vw - 40px);

    .title {
      font-size: 26px;
    }
    .span {
      font-size: 28px;
    }
    .container {
      display: none;
    }

    .sliderContainer {
      margin-top: 28px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      gap: 20.3px;
    }
  
    .image {
      width: 100%;
      height: calc((100vw - 40px)*0.72);
      max-height: 367px;
      object-fit: cover;
      border-radius: 20px;
    }
  
  
  
    .slider__dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 7.71px;
      width: calc(100% - 40px);
  
      &_prev, &_next {
        width: 79px;
        height: 13.49px;
        background-color: transparent;
        color: #247251;
        background-image: url(../../images/arrow_left.svg);
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }
  
      &_next {
        background-image: url(../../images/arrow_right.svg);
      }
    }
  
    .slider__dot {
      width: 11.57px;
      height: 11.57px;
      border: 1px solid #247251;
      border-radius: 10px;
      cursor: pointer;
  
      &_active {
        width: 19.28px;
        height: 19.28px;
        background-color: #247251;
      }
    }
  }
}
