@import '../../utils/variables/global';

.post {
  padding: 0 320px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 56px;
    font-family: var(--font-primary);
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 24px;
  }

  .previewText {
    color: #000;
    font-size: 20px;
    font-family: var(--font-primary);
    line-height: 135%;
    margin-bottom: 24px;
  }

  .readingTime {
    margin: 0 0 40px;
    font-family: var(--font-primary);
    color: var(--text-color);
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 32px;
      height: 32px;
      background-image: url(../../images/time.svg);
      background-position: center;
      background-size: 26px 26px;
      background-repeat: no-repeat;
    }
  }

  .publishDate {
    margin: 0;
    font-family: var(--font-primary);
    color: #121210;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.56px;
  }
}

.markdown__container {
  margin-top: 119px;
  padding: 64px 290px 90px;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100%;

  .reactMarkdown {
    display: flex;
    flex-direction: column;
    color: #000;
    font-family: var(--font-primary);

    h2 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
    }

    p {
      padding: 10px 0;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
    }

    ol {
      padding-left: 20px;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
    }

    img {
      width: 100%;
      height: 320px;
      border-radius: 20px;
    }
  }

  .tags {
    list-style: none;
    display: flex;
    gap: 5px;
    align-self: flex-start;

    .tag {
      font-size: 20px;
      font-family: var(--font-primary);
      font-weight: 700;
      line-height: 120%;
    }
  }
}

.more__posts {
  padding: 76px 320px;
  width: 100%;
  box-sizing: border-box;

  .title {
    @include h2_font;
  }
}

.list {
  margin-top: 16px;
  display: flex;
  gap: 40px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

@media screen and (max-width: 1290px) {
  .post {
    padding: 0 20px;
    align-items: flex-start;
    box-sizing: content-box;
  }

  .markdown__container {
    padding: 0 20px;
    box-sizing: border-box;
    background-color: transparent;
    margin-top: 80px;
    width: 100%;
  }

  .more__posts {
    .title {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }

    padding: 80px 0 0;
    width: 320px;
  }
}
