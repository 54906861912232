@use "../../utils/variables/global.scss";

.sectiongallery {
  margin-top: 140px;
  max-width: 1280px;
  width: 100%;


  .titlewrapper {
    margin-bottom: 55px;
    max-width: 592px;
    display: flex;
    flex-direction: column;
  }

  .titlewrapper__description {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--text-color);
  }

  .titlewrapper__title {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 110%;
    color: var(--text-color);

    span {
      font-family: var(--font-secondary);
      font-style: italic;
      font-weight: 600;
      font-size: 58px;
      line-height: 110%;
      color: var(--text-color-secondary);
    }
  }


  .slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1280px;
    overflow: hidden;
    gap: 32px;
  }

  .image {
    width: 290px;
    height: 419px;
    object-fit: cover;
  }



  .slider__dots {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;

    &_prev, &_next {
      width: 82px;
      height: 14px;
      background-color: transparent;
      color: #247251;
      background-image: url(../../images/arrow_left.svg);
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }

    &_next {
      background-image: url(../../images/arrow_right.svg);
    }
  }

  .slider__dot {
    width: 12px;
    height: 12px;
    border: 1.5px solid #247251;
    border-radius: 10px;
    cursor: pointer;

    &_active {
      width: 20px;
      height: 20px;
      background-color: #247251;
    }
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .sectiongallery {
    margin-top: 80px;

    .titlewrapper {
      margin: 0 0 40px 20px;
    }
  
    .titlewrapper__description {
      font-size: 10px;
      letter-spacing: 0.02em;
    }
  
    .titlewrapper__title {
      font-size: 26px;
  
      span {
        font-size: 28px;
      }
    }

    .slider {
      gap: 36px;
  
      .active {
        z-index: 100;
      }

      .image {
        width: 252px;
        height: 361px;
      }
  
      &__dots {
        gap: 7.71px;
        width: calc(100% - 40px);
  
        &_prev, &_next {
          width: 79px;
          height: 13.49px;
        }
      }
  
      &__dot {
        width: 11.57px;
        height: 11.57px;
        border: 1px solid #247251;
  
        &_active {
          width: 19.28px;
          height: 19.28px;
        }
      }
    }
  }

  .slider > div > div {
    justify-content: center;
    // gap: 36px;
  }
}
