@use "../../utils/variables/global.scss";

.section {
  margin-top: 140px;
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-columns: 550px 1fr;
  grid-template-rows: auto auto auto;
  gap: 24px 88px;
}

.title {
  grid-row: 1;
  max-width: 530px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary);
  }
}

.description {
  grid-row: 2;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);

  &:first-of-type {
    padding-bottom: 10px;
  }
}

.image {
  grid-row: 3;
  margin-top: 12px;
  width: 100%;
}

.accentWrapper {
  grid-row: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  position: relative;
  font-family: 'Raleway', sans-serif;

  div:nth-child(1) {
    position: absolute;
    top: 6%;
    right: 43%;
  }
  
  div:nth-child(2) {
    grid-column: 2;
    grid-row: span 2;
    position: absolute;
    top: 48%;
    right: -6%;
  }

  div:nth-child(3) {
    grid-column: 1;
    position: absolute;
    top: 59%;
    right: 44%;
  }
}

.ellipse {
  margin-bottom: 50px;
  width: 355px;
  height: 258px;
  border: 1px solid #121210;
  border-radius: 50%;
  transform: rotate(41.35deg);
}

.accentTitle {
  margin-top: 68px;
  margin-left: 75px;
  max-width: 204px;
  transform: rotate(-41.35deg);
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.accentSubtitle {
  transform: rotate(-41.35deg);
  margin-top: 8px;
  margin-left: 160px;
  max-width: 198px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
}

.twoColumn {
  position: absolute;
  top: 240px;
  right: 30px;
}

.test {
  width: 355px;
  height: 260px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 50%;
  border: 1px solid #121210;
  transform: rotate(41.35deg);

  h3 {
    max-width: 205px;
    width: 100%;
    margin: 100px 50px 15px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.04em;
    transform: rotate(-41.35deg);

    p {
      font-weight: 700;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .paragraph {
    max-width: 205px;
    width: 100%;
    margin: -75px 130px;
    font-weight: 400;
    font-size: 17px;
    line-height: 135%;
    transform: rotate(-41.35deg);
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 80px;
    grid-template-rows: auto 475px auto;
    grid-template-columns: calc(100% - 40px);
    gap: 20px;
    justify-content: center;
  }
  
  .title {
    font-size: 29px;
    grid-row: 1;
  
    span {
      font-size: 28px;
    }
  }
  
  .description {
    grid-row: 3;
    font-size: 16px;
  
    &:first-of-type {
      margin: 42px 0 0 0;
      padding: 0;
    }
  }
  
  .image {
    display: none;
  }
  
  .accentWrapper {
    margin-top: 60px;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: 100%;

    div:nth-child(1) {
      position: static;
    }
  
    div:nth-child(2) {
      position: static;

      h3 {
        p {
          padding-left: 23px;
        }
      }

      h3::after{
        top: -47px;
        left: -12px;
      }
    }
  
    div:nth-child(3) {
      position:static;
      margin-top: -8px;
    }
  }
  
  .test {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    border: none;
    transform: none;
    width: 100%;
  
    h3 {
      width: 100%;
      margin: 0;
      text-transform: uppercase;
      font-size: 16px;
      transform: none;
      position: relative;
      padding-left: 11px;

      p {
        font-weight: 600;
        font-size: 16px;
      }
    }

    h3::after {
        content: '';
        display: block;
        position:absolute;
        width: 172px;
        height: 123px;
        border-radius: 50%;
        border: 1px solid #121210;
        transform: rotate(41.35deg);
        top: -53px;
        left: -12px;
    }
  
    .paragraph {
      width: 100%;
      margin: 0;
      font-size: 16px;
      transform: none;
    }
  }
}
