@use '../../utils/variables/global.scss';

.list {
  display: flex;
  justify-content: space-between;
  gap: 23px;
  padding: 44px 0 36px;
  position: relative;
}

.item {
  position: relative;
  &:hover svg {
    stroke: var(--color-green-accent);
    transition: 0.5s;
  }
}

.link {
  display: block;
}

.item,
.button,
.link {
  height: 32px;
}

.active {
  stroke: var(--color-green-accent);
}

.button {
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.decoration {
  width: 360px;
  height: 444px;
  position: absolute;
  top: 0;
  right: -140px;
  z-index: -1;
  background-color: var(--bg-green-primary);
}

@media (max-width: global.$breakpoint-small-laptop) {
  .list {
    padding: 45px 24px 31px 26px;
  }

  .decoration {
    right: 0;
    width: 302px;
    height: 222px;
  }
}

@media (max-width: global.$breakpoint-tablet) {
  .list {
    padding: 31px 20px 56px 11px;
    gap: 12px;
  }

  .item,
  .button,
  .link,
  .icon {
    width: 24px;
    height: 24px;
  }

  .decoration {
    right: 0;
    width: 199px;
    height: 111px;
  }
}
