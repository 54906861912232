@use "../../utils/variables/global.scss";

.switchButtonWrapper {
  padding: 7px 4px 6px 5px;
  display: flex;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 51px;
  height: 31px;
  background: var(--color-green-grey-light);
  border-radius: 51px;
  position: relative;
  transition: all .2s ease;

  
}

.switchLabel .switchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  transition: all .2s ease;
  background: var(--color-white);
  box-shadow: 1px 2px 3px 1px rgba(10, 10, 10, 0.1);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchLabel:active .switchButton {
  width: 35px;
}

.switchLabelActive {
background: var(--color-green-accent);
}

@media screen and (max-width: global.$breakpoint-mobile) {

}
