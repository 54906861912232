@use "../../utils/variables/global.scss";

.section {
  margin-top: 140px;
  width: 100%;
  background-color: var(--bg-green-primary);
  display: flex;
  justify-content: center;

}

.block {
  padding: 80px 0;
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: space-between
}

.container {
  max-width: 620px;
}

.imgContainer {
  position: relative;
}

.figureWrapper {
  position: relative;
  z-index: 2;
}

.figure {
  display: block;
  max-width: 340px;
  margin-left: auto;
  margin-right: 0;
  z-index: 2;
}

.figureBorder {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 349px;
  height: 349px;
  border: 1px solid #121210;
  border-radius: 200px 50px 50px 50px;
  z-index: global.$layer-under-content;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: -40%;
  left: 0;
}

.subtitle {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-color);
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  color: var(--text-color);

  span {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 58px;
    line-height: 110%;
    color: var(--text-color-secondary)
  }
}

.description {
  margin-top: 24px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  color: var(--text-color);

  &:last-of-type {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .section {
    margin-top: 80px;
    background-color: transparent;
    min-height: 356px;
  }

  .block {
    padding: 40px 20px;
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(204, 218, 198, 0.9), rgba(204, 218, 198, 0.9)), url('../../images/about-us__img-rectangle.png');
    background-position: center;
    background-size: cover;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
  
  .imgContainer {
    display: none;
  }

  .subtitle {
    font-size: 10px;
  }

  .title {
    font-size: 28px;

    span {
      font-size: 28px;
    }
  }

  .description {
    font-size: 16px;
    margin-top: 12px;
  }

  .btn {
    margin: 20px auto 0;
    padding: 11px 40px;
    width: 320px;
    background-color: transparent;
    border: 1.5px solid var(--btn-color);
    color: var(--btn-color);
    font-size: 16px;
    line-height: 135%;
  }
}
