@use "../../utils/variables/global.scss";

.button {
  transition: all .6s ease;
  padding: 10px 40px;
  border-radius: 56px;
  font-family: var(--font-primary);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  height: 56px;
  line-height: 135%;
  box-sizing: border-box;
  cursor: pointer;

  &.outline {
    border: 1.5px solid var(--color-green-accent);
    color: var(--color-green-accent);
    background-color: transparent;

    &:hover {
      background-color: var(--color-green-accent);
      color: var(--color-white);
    }

    &:active {
      background-color: transparent;
      border: 1.5px solid var(--color-green-active);
      color: var(--color-green-active);
    }
  }

  &.fill {
    border: none;
    background-color: var(--color-green-accent);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-green-accent-active);
    }

    &:active {
      background: var(--color-green-active);
      color: var(--color-white-active);
    }

    &:disabled {
      background-color: var(--color-green-grey);
      color: var(--color-white-active);
    }
  }

  &.decoration {
    position: relative;

    &::before {
      position: absolute;
      padding: 10px 40px;
      height: 56px;
      box-sizing: border-box;
      width: 100%;
      top: 8px;
      left: 8px;
      content: '';
      border-radius: 56px;
      border: 1px solid var(--color-black);
      z-index: global.$layer-under-content;
    }
  }

  &.decorationWhite {
    position: relative;

    &::before {
      position: absolute;
      padding: 10px 40px;
      height: 56px;
      box-sizing: border-box;
      width: 100%;
      top: 8px;
      left: 8px;
      content: '';
      border-radius: 56px;
      border: 1px solid var(--color-white);
      z-index: global.$layer-under-content;
    }
  }
}

.fillButton {
  width: 100%;
}

@media screen and (max-width: global.$breakpoint-card-curses) {
  .button {
    font-family: var(--font-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    height: 44px;

    &.decoration {
      &::before {
        height: 44px;
      }
    }

    &.decorationWhite {
      &::before {
        height: 44px;
      }
    }
  }
}

//StoryBook
.buttonStoryWrapper {
  width: 400px;
}