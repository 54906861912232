@use "../../utils/variables/global.scss";

.footer {
  margin-top: 140px;
  width: 100%;
  background-color: var(--bg-green-primary);
  border-radius: 0px 550px 0px 0px;
}

.block {
  margin: 88px auto 0;
  max-width: 1280px;
  width: 100%;
}

@media screen and (max-width: 876px) {
  .footer {
    border-radius: 0px 200px 0px 0px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 696px) {
  .footer {
    border-radius: 0px 100px 0px 0px;
  }
}
