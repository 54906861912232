@use "../../utils/variables/global.scss";

.formWrapper {
  max-width: 510px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  grid-column: 3;
  grid-row: 1 / 2;
  z-index: 1;
}

.formTitle {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--text-color);
}

.form {
  margin-top: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btnWrapper {
  max-width: 293px;
  margin-top: 16px;
  position: relative;
}

@media screen and (max-width: 876px) {
  .btnWrapper {
    max-width: 325px;
    width: 44vw;
  }

  .wide {
    width: 93vw;
    max-width: 696px;
  }
}

@media screen and (max-width: 696px) {
  .formWrapper {
    width: 100%;
    border-top: 1px solid #819286;
    padding: 0 20px;
  }

  .formTitle {
    line-height: 135%;
    margin-top: 40px;
  }

  .btnWrapper {
    width: fit-content;
    max-width: 400px;
    margin-top: 3px;

    .submit {
      width: calc(100% - 10px);
      height: 44px;
      border-radius: 56px;
      padding: 11px 40px;
      font-size: 16px;
      line-height: 135%;
      margin: 0 auto;
    }

    .btnBorder {
      width: calc(100% - 10px);
      height: 44px;
      left: 5px;
      top: 5px;
      border-radius: 56px;
    }
  }

  .form {
    margin-top: 12px;
    width: 83vw;
    align-items: center;
  }
}