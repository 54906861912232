@use "../../utils/variables/global.scss";

.sliderCard {
  min-width: 1280px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #000;
    opacity: .7;
    z-index: 0;
  }

  img {
    height: 480px;
    object-fit: cover;
    border-radius: 20px;
  }

  .wrapper {
    position: absolute;
    top: 100px;
    left: 110px;
    color: #fff;

    .title {
      max-width: 660px;
      width: 100%;
      margin-bottom: 16px;
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 135%;
    }

    .paragraph {
      max-width: 660px;
      width: 100%;
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 135%;
      opacity: .8;
    }
  }

  & > button {
    position: absolute;
    bottom: 70px;
    left: 110px;
  }

}

@media screen and (max-width: global.$breakpoint-mobile) {

  .sliderCard {
    min-width: calc(100vw - 40px);

    &:before {
      border-radius: 16px;
    }

    img {
      height: 370px;
      border-radius: 16px;
    }

    .wrapper {
      top: 40px;
      left: 24px;
      width: calc(100% - 48px);
  
      .title {
        width: 100%;
        margin-bottom: 12px;
        font-size: 20px;
      }
  
      .paragraph {
        width: 100%;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > button {
      left: 0;
      right: 0;
      margin: auto;
      bottom: 40px;
      width: calc(100% - 48px);
    }
  }
}
