@use "../../utils/variables/global.scss";

.popup {
  position: absolute;
  top: 43px;
  right: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: .5s;
  z-index: global.$layer-of-popup;

  &Open {
    opacity: 1;
    visibility: visible;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: global.$layer-under-content;
  opacity: 0;
  visibility: hidden;
  transition: .5s;

  &Open {
    opacity: 1;
    visibility: visible;
  }
}

.contentWrapper {
  // width: 100%;
  // max-width: 295px;
  width: 295px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--bg-color-white);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 8px 8px 30px 6px rgba(214, 212, 209, 0.60);
}

.link {
  text-decoration: none;
  // width: 231px;
  font-family: var(--font-primary);
  font-weight: 400;
  padding: 8px 32px;
  line-height: 135%;
  font-size: 20px;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    transition: all .3s ease;
    background-color: var(--color-beige-primary);
  }

  &:active {
    background-color: var(--color-green-primary);
    transition: all .3s ease;
  }
}

.button {
  all: unset;
  font-family: var(--font-primary);
  font-weight: 400;
  padding: 8px 32px;
  line-height: 135%;
  font-size: 20px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  transition: all .3s ease;

  &:hover {
    background-color: var(--color-beige-primary);
    transition: all .3s ease;
  }

  &:active {
    background-color: var(--color-green-primary);
    transition: all .3s ease;
  }
}

.storybookWrapper {
  display: flex;
  width: 100px;
  height: 300px;
  padding-right: 200px;
  position: relative;
}

@media screen and (max-width: global.$breakpoint-mobile) {
  .contentWrapper {
    width: 320px;
    max-width: unset;
    padding: 20px 0;
  }

  .link {
    padding: 8px 20px;
    font-size: 16px;
  }

  .button {
    padding: 8px 20px;
    font-size: 16px;
  }

  .popup {
    top: 46px;
    right: -103px;
  }

}