@import '../../vendor/normalize.css';
@import '../../vendor/fonts/stylesheet.scss';
@import '../../utils/variables/global.scss';

.App,
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  width: 100%;
}
