@use "../../utils/variables/global.scss";

.section {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.block {
  padding: 0;
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 110px;
}

.formContainer {
  width: 620px;
  height: 760px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: var(--bg-color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: global.$base-layer;
}

.inputsWrapper {
  width: 510px;
}

.buttonWrapper {
  padding-top: 43px;
}

.title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 135%;
  color: var(--text-color);
  padding: 44px 55px 64px 55px;
}

.list {
  padding: 55px;
  min-width: 510px;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: baseline;
  list-style-type: none;
}

.step {
  text-decoration: none;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 47px;
  font-size: 17px;
  color: var(--color-green-grey);
  margin-left: auto
}

.text {
  width: 510px;
  height: 54px;
  font-family: var(--font-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--text-color);
  text-align: center;
}

.responseStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titleError {
  text-align: center;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 135%;
  color: var(--text-color);
  padding: 105px 55px 20px 55px;
}

.errorButtonWrapper {
  padding-top: 63px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.textSendData {
padding-top: 137px;
margin-bottom: 3px;
}

.inputsFieldMargin {
margin: 0;
}

.textError {
  padding-top: 0;
}

@media screen and (max-width: global.$breakpoint-mobile) {

  .step {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.56px;
  }

  .formContainer {
    margin: 0 20px;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
  }

  .list {
    min-width: 100%;
    padding: 0;
    padding-top: 8px;
  }

  .inputsWrapper {
    width: 100%;
  }

  .title {
    font-size: 20px;
    padding: 43px 0 30px 0;
  }

  .inputsFieldMargin {
    margin-bottom: 25px;
  }

  .buttonWrapper {
    padding-top: 19px;
    width: 280px;
    display: flex;
    flex-direction: column;
  }

  .text {
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 145%;
    text-align: left;
  }

  .textSendData {
    padding-top: 55px;
    margin-bottom: 0;
    }

    .errorButtonWrapper {
      padding-top: 35px;
      width: 100%;
      padding-bottom: 10px;
    }

    .titleError {
      font-size: 20px;
      padding: 0;
    }

    .textError {
      text-align: center;
      padding-top: 17px;
    }
}