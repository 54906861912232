@use "../../utils/variables/global.scss";

.popup {
  position: fixed;
  top: calc(( 100dvh - 156px ) / 2);
  left: calc(( 100dvw - 320px ) / 2);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  z-index: global.$layer-of-popup;
  pointer-events: none;

  &Open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.overlay {
  background: var(--color-overlay);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: global.$layer-of-popup;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;

  &Open {
    opacity: 1;
    visibility: visible;
  }
}

.contentWrapper {
  width: 320px;
  height: auto;
  border-radius: 12px;
  border: 1px solid var(--color-green-accent);
  box-sizing: border-box;
  background-color: var(--bg-color-white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.button {
  all: unset;
  cursor: pointer;
  height: 52px;
  box-sizing: border-box;
  font-family: var(--font-primary), sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;
  
  color: var(--text-color);
  padding: 10px 20px 10px 24px;
  border-bottom: 1px solid var(--color-green-accent);
  background-color: var(--color-background-color);
  transition: all .3s ease;

  &:last-child {
    border-bottom: unset;
  }

  &:hover {
    color: var(--color-green-accent);
    background-color: var(--color-green-grey-light);
  }
}

  .active {
    color: var(--color-background-color);
    background-color: var(--color-green-accent);
  }