.title {
  margin-top: 48px;
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 32px;
  line-height: 135%;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 510px);
  gap: 47px;
  justify-content: center;
  margin-top: 28px;
}

.infoTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 28px;
}

.iconContainersWrapper {
  margin-bottom: 24px;
}

.form {
  width: 100%;
}

.notAuthorizedUserContainer {
  width: 100%;
}

.linkContainer {
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-green-primary);
  border-radius: 20px;
  margin-bottom: 26px;
  box-sizing: border-box;
}

.linkText {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
}

.link {
  color: var(--color-green-accent);

  &:hover {
    opacity: 0.7;
  }
}

.buttonContainer {
  margin-top: 5px;
  width: 290px;
}

@media screen and (max-width: 1320px) {
  .title {
    font-size: 26px;
    line-height: 130%;
    margin-top: 25px;
  }

  .container {
    grid-template-columns: 398px 1fr;
    gap: 20px;
    margin-top: 23px;
  }

  .infoTitle {
    line-height: 135%;
  }

  .form {
    width: 278px;
  }

  .linkContainer {
    margin-bottom: 24px;
  }

  .linkText {
    line-height: 135%;
  }

  .buttonContainer {
    margin-top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 743px) {
  .title {
    margin: 25px 44px 0 20px;
  }

  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 472px 1fr;
    gap: 32px;
    margin-top: 2px;
    width: 100%;
  }

  .infoTitle {
    margin-bottom: 23px;
  }

  .form {
    margin-top: -10px;
    width: 320px;
  }

  .linkContainer {
    padding: 10px;
  }

  .buttonContainer {
    margin-top: 5px;
    width: 100%;
  }

  .submitButton {
    margin-top: 5px;
    width: 100%;
  }

  .info {
    padding: 0 20px;
    width: 320px;
  }
}

