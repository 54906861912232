.day {
  font-weight: 400;
  font-size: 17px;
  line-height: 135%;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey {
  color: var(--color-light-grey);
}

.active {
  border: 1px solid var(--color-green-accent);
  box-sizing: border-box;
}

.selected {
  background-color: var(--color-green-primary);
  border: none;
}

.button {
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
